var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.exportVisible
        ? [
            _c(
              "div",
              { staticClass: "top_navigation" },
              [
                _c(
                  "HeaderBack",
                  { on: { back: _vm.goBack } },
                  [
                    _c("template", { slot: "left" }, [
                      _c(
                        "span",
                        {
                          staticClass: "top_nav_title",
                          attrs: { title: _vm.salaryItemInfo.groupName },
                        },
                        [_vm._v(_vm._s(_vm.salaryItemInfo.groupName))]
                      ),
                      _c("span", { staticClass: "top_nav_time" }, [
                        _vm._v(
                          _vm._s(_vm.salaryPeriod.startDate) +
                            " - " +
                            _vm._s(_vm.salaryPeriod.endDate)
                        ),
                      ]),
                    ]),
                    _c(
                      "template",
                      { slot: "center" },
                      [
                        _c(
                          "a-steps",
                          {
                            attrs: { current: _vm.current },
                            on: { change: _vm.currentChange },
                          },
                          _vm._l(_vm.steps, function (item) {
                            return _c("a-step", {
                              key: item.key,
                              attrs: {
                                disabled: _vm.isNotDetail,
                                title: item.title,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "div",
              { staticClass: "steps-content" },
              [
                _c(_vm.showContentName[_vm.current], {
                  tag: "component",
                  attrs: { "salary-item-info": _vm.salaryItemInfo },
                  on: { handleContinue: _vm.handleContinue },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "steps-action" },
              [
                _c(
                  "a-space",
                  [
                    !_vm.isNotDetail || (_vm.current > 0 && _vm.current !== 3)
                      ? _c(
                          "a-button",
                          { staticClass: "previous", on: { click: _vm.prev } },
                          [_vm._v(" 上一步 ")]
                        )
                      : _vm._e(),
                    (!_vm.isNotDetail && _vm.current !== 4) ||
                    (_vm.current < _vm.steps.length - 1 && _vm.current !== 4)
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.next },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.current === 2 && _vm.isNotDetail
                                    ? "锁定应发工资"
                                    : "下一步"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.current == 4
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.goBack },
                          },
                          [_vm._v(" 返回首页 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _vm.batchType === "batchFixedSalary"
              ? _c("BatchImport", {
                  attrs: {
                    multiple: false,
                    "file-size": 20,
                    title: "人员批量定薪",
                    "import-template": "batchFixedSalary",
                    "extra-params": {
                      groupCalcId: _vm.salaryItemInfo.groupCalcId,
                    },
                  },
                  on: { goBack: _vm.batchHide },
                })
              : _vm._e(),
            _vm.batchType === "batchChangeSalary"
              ? _c("BatchImport", {
                  attrs: {
                    multiple: false,
                    "file-size": 20,
                    title: "人员批量调薪",
                    "import-template": "batchChangeSalary",
                    "extra-params": {
                      groupCalcId: _vm.salaryItemInfo.groupCalcId,
                    },
                  },
                  on: { goBack: _vm.batchHide },
                })
              : _vm._e(),
            _vm.batchType === "batchSetSalaryCalc"
              ? _c("BatchImport", {
                  attrs: {
                    multiple: false,
                    "file-size": 20,
                    title: "批量导入合计",
                    "import-template": "batchSetSalaryCalc",
                    "extra-params": _vm.extraParams,
                  },
                  on: { goBack: _vm.batchHide },
                })
              : _vm._e(),
            _vm.batchType === "salaryIndexItemImportIndex"
              ? _c("BatchImport", {
                  attrs: {
                    multiple: false,
                    "file-size": 20,
                    title: "批量导入明细",
                    "import-template": "salaryIndexItemImportIndex",
                    "extra-params": _vm.extraParams,
                  },
                  on: { goBack: _vm.batchHide },
                })
              : _vm._e(),
          ],
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.lockSalaryVisible,
            title: "锁定应发工资",
            closable: "",
            size: "small",
          },
          on: {
            ok: _vm.handleOk,
            cancel: function ($event) {
              _vm.lockSalaryVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "CbAlert",
                { attrs: { type: "warning", "show-icon": "" } },
                [
                  _c("template", { slot: "content" }, [
                    _c("div", [
                      _vm._v(
                        " 1.锁定当前薪资组应发工资后，参与计算的各薪资项数据将无法更改，建议您先确认核算薪资的结果，再进行锁定。 "
                      ),
                    ]),
                    _vm.notLockSalaryNum > 1
                      ? _c("div", [
                          _vm._v(
                            " 2.点击确认，当前薪资组应发工资锁定完成，并将返回计薪首页继续剩余薪资组计薪。 "
                          ),
                        ])
                      : _vm._e(),
                    _vm.notLockSalaryNum === 1
                      ? _c("div", [
                          _vm._v(
                            " 2.当前计薪周期的应发工资已全员计算完毕，点击确认，将锁定该薪资组应发工资并前往个税业务版块，确认锁定并跳转吗？ "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }